import React from "react";
import "../styles/Hero.css";

const Hero = () => {
  return (
    <div className="hero-container">
      <section className="hero">
        <h1>Nisarga Documentation</h1>
        <p>Your guide to mastering the Nisarga add-on for Blender.</p>
      </section>
    </div>
  );
};

export default Hero;
