import React, { useState, useEffect } from "react";
import {
  FaDownload,
  FaWrench,
  FaCogs,
  FaQuestionCircle,
  FaPlayCircle,
  FaSignInAlt,
  FaCheckCircle,
  FaClipboardList,
  FaSearch,
  FaChevronDown,
} from "react-icons/fa";
import "../styles/Sidebar.css";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [expandedSections, setExpandedSections] = useState({
    general: true,
    account: true,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6, // Trigger when 60% of the section is visible
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveLink(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const sections = document.querySelectorAll("section");
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  const filteredLinks = (links) =>
    links.filter((link) =>
      link.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const generalLinks = [
    { id: "introduction", label: "Introduction", icon: <FaClipboardList /> },
    { id: "downloading", label: "Downloading", icon: <FaDownload /> },
    { id: "installing", label: "Installing", icon: <FaWrench /> },
    { id: "setup", label: "Setup", icon: <FaCogs /> },
  ];

  const accountLinks = [
    { id: "signing-up", label: "Signing Up", icon: <FaSignInAlt /> },
    { id: "approval-process", label: "Approval Process", icon: <FaCheckCircle /> },
    { id: "checking-status", label: "Checking Status", icon: <FaClipboardList /> },
    { id: "logging-in", label: "Logging In", icon: <FaSignInAlt /> },
  ];

  return (
    <aside className="sidebar">
      <div className="sidebar-header">
        <h1 className="sidebar-title">Nisarga Docs</h1>
        <div className="search-bar">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <nav className="sidebar-nav">
        <div className="sidebar-section">
          <div
            className="sidebar-section-header"
            onClick={() => toggleSection("general")}
          >
            <span>General</span>
            <FaChevronDown
              className={`chevron ${expandedSections.general ? "open" : ""}`}
            />
          </div>
          {expandedSections.general && (
            <div className="sidebar-items">
              {filteredLinks(generalLinks).map((link) => (
                <a
                  key={link.id}
                  href={`#${link.id}`}
                  className={`tab ${activeLink === link.id ? "active" : ""}`}
                >
                  {link.icon} {link.label}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="sidebar-section">
          <div
            className="sidebar-section-header"
            onClick={() => toggleSection("account")}
          >
            <span>Account</span>
            <FaChevronDown
              className={`chevron ${expandedSections.account ? "open" : ""}`}
            />
          </div>
          {expandedSections.account && (
            <div className="sidebar-items">
              {filteredLinks(accountLinks).map((link) => (
                <a
                  key={link.id}
                  href={`#${link.id}`}
                  className={`tab ${activeLink === link.id ? "active" : ""}`}
                >
                  {link.icon} {link.label}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="sidebar-items">
          <a
            href="#faq"
            className={`tab ${activeLink === "faq" ? "active" : ""}`}
          >
            <FaQuestionCircle className="icon" /> FAQ
          </a>
          <a
            href="#tutorials"
            className={`tab ${activeLink === "tutorials" ? "active" : ""}`}
          >
            <FaPlayCircle className="icon" /> Tutorials
          </a>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
