import React from "react";
import "../styles/Tutorials.css";

const Tutorials = ({ data }) => {
  return (
    <section id="tutorials" className="tutorials-section">
      <h2 className="tutorials-title">Tutorials</h2>
      <div className="tutorials-grid">
        {data.map((tutorial, index) => (
          <div key={index} className="tutorial-card">
            <iframe
              src={tutorial.videoUrl}
              title={tutorial.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="tutorial-video"
            ></iframe>
            <h3 className="tutorial-title">{tutorial.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Tutorials;
