import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebaseConfig'; // Adjust the path if necessary
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth"; // Import signOut
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import './ApprovalStatus.css';

const ApprovalStatus = () => {
  const [status, setStatus] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [loading, setLoading] = useState(true); // Add a loading state
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setStatus(userData.status);
          setRejectionReason(userData.rejectionReason);
        }
      } else {
        setStatus(null); // Reset status if no user is logged in
      }
      setLoading(false); // End loading state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Optional: Show a loading message or spinner
  }

  return (
    <div className="approval-status-container">
      <h1>Welcome to Nisarga</h1>
      <div className="status-indicator">
        <p>Status: <span className={`status ${status}`}>{status ? capitalizeFirstLetter(status) : "Not available"}</span></p>
      </div>
      <div className="status-explanation">
        {status === 'pending' && <p>Your account is being reviewed and will be processed within 24 hours.</p>}
        {status === 'rejected' && (
          <div>
            <p>Your account has been rejected.</p>
            <p>Reason: {rejectionReason}</p>
            <p>If you believe this is a mistake, please contact our support team for assistance.</p>
          </div>
        )}
        {status === 'approved' && <p>Congratulations! Your account is verified. You can now use the add-on in Blender.</p>}
      </div>
      <div className="support-info">
        <p>If you have any queries, you can join our <a href="https://discord.gg/Z26DPMFZ8p" target="_blank" rel="noopener noreferrer">Discord server</a> or email us at <a href="mailto:contact@ranimationstudios.com">contact@ranimationstudios.com</a>.</p>
      </div>
      <button onClick={handleLogout} className="logout-button">Logout</button>
    </div>
  );
};

export default ApprovalStatus;
