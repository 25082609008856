import React from "react";
import Lightbox from "./Lightbox";
import "../styles/ContentSection.css";
import { FaExclamationTriangle, FaLightbulb } from "react-icons/fa";
import MarketplaceTabs from "./MarketplaceTabs";

const ContentSection = ({ id, title, content, image, note, warning }) => {
  return (
    <section id={id} className="content-section">
      <div className="content-card">
        <h2 className="content-title">{title}</h2>
        <div
          className="content-body"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {id === "downloading" && <MarketplaceTabs />}
        {image && <Lightbox image={image} altText={`${title} illustration`} />}
        {note && (
          <div className="note-box">
            <FaLightbulb className="callout-icon" /> {note}
          </div>
        )}
        {warning && (
          <div className="warning-box">
            <FaExclamationTriangle className="callout-icon" /> {warning}
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentSection;
