import React, { useState } from "react";
import "../styles/MarketplaceTabs.css";

const MarketplaceTabs = () => {
  const [activeTab, setActiveTab] = useState("davoxel");

  const marketplaces = {
    davoxel: {
      name: "Davoxel",
      link: "https://www.davoxel.com/my-library",
      instructions: `
        To access your purchased assets on Davoxel:
        <ol>
          <li>Log in to your Davoxel account.</li>
          <li>Navigate to the <strong>My Library</strong> section.</li>
          <li>Locate the Nisarga add-on and click on the <strong>View Details</strong> button.</li>
          <li>This will open sidebar showing product files.</li>
          <li>Download <strong>Nisarga.zip</strong>.</li>
        </ol>
      `,
    },
    blenderMarket: {
      name: "BlenderMarket",
      link: "https://blendermarket.com/account/orders",
      instructions: `
        To access your purchased assets on BlenderMarket:
        <ol>
          <li>Log in to your BlenderMarket account.</li>
          <li>Navigate to the <strong>Orders</strong> section.</li>
          <li>Find your order for Nisarga and click on the Go to Downloads button.</li>
          <li>Download <strong>Nisarga.zip</strong>.</li>
        </ol>
      `,
    },
    flippedNormals: {
      name: "Flipped Normals",
      link: "https://flippednormals.com/my-library",
      instructions: `
        To access your purchased assets on Flipped Normals:
        <ol>
          <li>Log in to your Flipped Normals account.</li>
          <li>Go to the <strong>My Library</strong> section.</li>
          <li>Click on product files, and Download <strong>Nisarga.zip</strong>.</li>
        </ol>
      `,
    },
    gumroad: {
      name: "Gumroad",
      link: "https://app.gumroad.com/library",
      instructions: `
        To access your purchased assets on Gumroad:
        <ol>
          <li>Log in to your Gumroad account.</li>
          <li>Navigate to the <strong>Library</strong> section.</li>
          <li>Find the Nisarga add-on and open it.</li>
          <li>Locate <strong>Nisarga.zip</strong> file and Download it.</li>
        </ol>
      `,
    },
    artstation: {
      name: "Artstation",
      link: "https://www.artstation.com/marketplace/library",
      instructions: `
        To access your purchased assets on Artstation:
        <ol>
          <li>Log in to your Artstation account.</li>
          <li>Go to the <strong>My Library</strong> section.</li>
          <li>Locate the Nisarga add-on and download it.</li>
        </ol>
      `,
    },
  };

  return (
    <div className="marketplace-tabs">
      <div className="tabs">
        {Object.keys(marketplaces).map((key) => (
          <button
            key={key}
            className={`tab-button ${activeTab === key ? "active" : ""}`}
            onClick={() => setActiveTab(key)}
          >
            {marketplaces[key].name}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <h3>{marketplaces[activeTab].name}</h3>
        <div
          className="instructions"
          dangerouslySetInnerHTML={{ __html: marketplaces[activeTab].instructions }}
        ></div>
        <a
          href={marketplaces[activeTab].link}
          target="_blank"
          rel="noopener noreferrer"
          className="download-button"
        >
          Visit Marketplace
        </a>
      </div>
    </div>
  );
};

export default MarketplaceTabs;
