import React from "react";
import Sidebar from "./components/Sidebar";
import Hero from "./components/Hero";
import ContentSection from "./components/ContentSection";
import FAQ from "./components/FAQ";
import Tutorials from "./components/Tutorials";
import "./styles/global.css";

// Image imports
import installingImage from "./images/Installing.gif"
import setupImage from "./images/Setup.png"
import signupPage from "./images/signup_page.png"
import approvalProcess from "./images/approval_process.png"
import approved from "./images/approved.png"
import assetBrowser from "./images/UI.png"


const contentData = [
  {
    id: "introduction",
    title: "Introduction",
    content: `
      <p>Welcome to the <strong>Nisarga Documentation</strong>. This guide is designed to help you get started with the Nisarga add-on, a comprehensive library of nature assets for Blender. Whether you're a beginner or an experienced user, you'll find everything you need to:</p>
      <ul>
        <li>Download and install the add-on.</li>
        <li>Set up your environment for seamless operation.</li>
        <li>Navigate through the features of Nisarga effectively.</li>
        <li>Troubleshoot common issues and find solutions.</li>
      </ul>
      <p>The documentation is structured into sections, allowing you to quickly access the information you need. From installation instructions to tutorials, we've got you covered.</p>
      <p class="note-box">
        <strong>Note:</strong> This documentation is regularly updated. Ensure you're using the latest version of the add-on and referring to the most recent version of this guide.
      </p>
    `
  },  
  {
    id: "downloading",
    title: "Downloading the Add-on",
    content: `
      <p>Follow these steps to download the Nisarga Add-on:</p>
      <ul>
        <li>Log in to the marketplace where you purchased the add-on.</li>
        <li>Go to the your marketplace's respective purchased product's section in your account.</li>
        <li>Find the file named <strong>"Nisarga.zip"</strong>.</li>
        <li>Click <strong>Download</strong> and save it to your computer.</li>
      </ul>
      <p class="note-box">
        <strong>Note:</strong> Save the file in a folder you can easily locate later, such as your "Downloads" folder.
      </p>
    `,
  },
  {
    id: "installing",
    title: "Installing the Add-on in Blender",
    content: `
      <p>Here’s how to install the downloaded add-on in Blender:</p>
      <ol>
        <li>Open Blender and ensure you’re using version <strong>4.2 or newer</strong>.</li>
        <li>Drag the <strong>"Nisarga.zip"</strong> file directly into the Blender interface.</li>
        <li>
          Go to <strong>Edit > Preferences > Add-ons</strong>, search for <strong>"Nisarga"</strong>, and enable it by
          clicking the checkbox.
        </li>
        <li>Restart Blender (optional) to ensure the add-on loads correctly.</li>
      </ol>
      <p class="warning-box">
        <strong>Warning:</strong> Ensure Blender isn’t running any large processes during installation.
      </p>
    `,
    image: installingImage,
  },
  {
    id: "setup",
    title: "Setting Up the Nisarga Add-On",
    content: `
      <p>After enabling the add-on, follow these steps to set it up:</p>
      <ol>
        <li>Open Blender Preferences by navigating to <strong>Edit > Preferences > Add-ons > Nisarga</strong>.</li>
        <li>Log in using the email and password you registered with.</li>
        <li>Choose a folder for storing downloaded assets. This is required to use the add-on.</li>
      </ol>
      <p class="note-box">
        <strong>Note:</strong> The asset directory is essential for managing downloaded assets. Make sure to choose a folder with sufficient storage space.
      </p>
    `,
    image: setupImage,
  },
  {
    id: "signing-up",
    title: "Signing Up",
    content: `
      <p>To use the Nisarga add-on, you must first create an account:</p>
      <ol>
        <li>Visit the <strong>Nisarga website</strong>: <a href="https://nisarga.ranimationstudios.com">nisarga.ranimationstudios.com</a>.</li>
        <li>Fill in the following details:
          <ul>
            <li><strong>Email:</strong> Use any email, though it’s recommended to use the one you purchased the add-on with.</li>
            <li><strong>Password:</strong> Create a strong password for your account.</li>
            <li><strong>Order ID:</strong> Enter the exact Order ID provided by the marketplace.</li>
          </ul>
        </li>
        <li>Click <strong>Sign Up</strong> to create your account.</li>
      </ol>
      <p class="note-box">
        <strong>Note:</strong> Ensure the Order ID is case-sensitive and entered correctly to avoid approval delays.
      </p>
    `,
    image: signupPage,
  },
  {
    id: "approval-process",
    title: "Approval Process",
    content: `
      <p>After signing up, your account will be reviewed for approval:</p>
      <ul>
        <li>Approval typically takes up to <strong>3 business days</strong>, but most accounts are approved within 24 hours.</li>
        <li>If additional details are required, you will receive an email with further instructions.</li>
      </ul>
      <p class="warning-box">
        <strong>Warning:</strong> Ensure the details you provided during sign-up (email, password, and Order ID) are accurate to avoid rejection.
      </p>
    `,
    image: approvalProcess,
  },
  {
    id: "checking-status",
    title: "Checking Account Status",
    content: `
      <p>You can check your account’s status while waiting for approval:</p>
      <ol>
        <li>Visit the <strong>Nisarga website</strong>: <a href="https://nisarga-download-manager.firebaseapp.com/">https://nisarga-download-manager.firebaseapp.com/</a>.</li>
        <li>Log in using your registered email and password.</li>
        <li>View your account status:
          <ul>
            <li><strong>Pending:</strong> Your account is still under review.</li>
            <li><strong>Rejected:</strong> Your account was not approved. A rejection reason will be displayed.</li>
            <li><strong>Approved:</strong> You can start using the add-on in Blender.</li>
          </ul>
        </li>
      </ol>
      <p class="note-box">
        <strong>Note:</strong> If your account is rejected, ensure you fix the mentioned issues and reapply.
      </p>
    `,
    image: approved,
  },
  {
    id: "logging-in",
    title: "Logging In",
    content: `
      <p>You can log in to your account through either Blender or the Nisarga website:</p>
      <ul>
        <li><strong>Through Blender:</strong>
          <ul>
            <li>Navigate to the Nisarga add-on Preferences in Blender.</li>
            <li>Enter your registered email and password.</li>
          </ul>
        </li>
        <li><strong>Through the Website:</strong>
          <ul>
            <li>Visit <a href="https://nisarga.ranimationstudios.com">https://nisarga.ranimationstudios.com/</a>.</li>
            <li>Enter your credentials to check your status or manage your account.</li>
          </ul>
        </li>
      </ul>
    `,
  },
  {
    id: "using-the-addon",
    title: "Using the Add-on",
    content: `
      <p>Once your account is approved and you’re logged in, follow these steps:</p>
      <ul>
        <li>Ensure you’ve set a valid asset directory for storing files.</li>
        <li>Add-on can now be accessible through <strong>N-panel</strong>.</li>
        <li>Start downloading assets directly from Blender using the add-on interface.</li>
        <li>Enjoy seamless access to your purchased variant of the Nisarga library.</li>
      </ul>
      <p class="note-box">
        <strong>Note:</strong> Ensure your internet connection is stable for uninterrupted downloads.
      </p>
    `,
    image: assetBrowser,
  },
];


const faqData = [
  {
    question: "How do I update the add-on?",
    answer: "Download the latest version from the marketplace and reinstall it by uninstalling previous versions.",
  },
  {
    question: "What are the system requirements?",
    answer: "Blender 4.2 or newer, with at least 4GB of RAM and 60GB of disk space(If you are conisdering downloading all of the assets in the library).",
  },
  {
    question: "Can I use Nisarga on multiple devices?",
    answer: "Yes, log in with your registered credentials on each device.",
  },
];

const tutorialsData = [
  {
    title: "Coming Soon...",
    videoUrl: "", // Replace with your video URL
  },
  {
    title: "Coming Soon...",
    videoUrl: "", // Replace with your video URL
  },
];


const DocumentationPage = () => {
  return (
    <div className="documentation-container">
      <Sidebar />
      <main className="main-content">
        <Hero />
        {contentData.map((section, index) => (
          <React.Fragment key={section.id}>
            <ContentSection {...section} />
            {index < contentData.length - 1 && <div className="divider"></div>}
          </React.Fragment>
        ))}
        <FAQ data={faqData} />
        <div className="divider"></div>
        <Tutorials data={tutorialsData} />
      </main>
    </div>
  );
}

export default DocumentationPage;
