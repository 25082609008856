import React from "react";
import "../styles/Lightbox.css";

const Lightbox = ({ image, altText }) => {
  const handleFullscreen = (e) => {
    const imgElement = e.target; // Get the clicked image element
    if (imgElement.requestFullscreen) {
      imgElement.requestFullscreen();
    } else if (imgElement.webkitRequestFullscreen) {
      imgElement.webkitRequestFullscreen(); // Safari
    } else if (imgElement.msRequestFullscreen) {
      imgElement.msRequestFullscreen(); // IE/Edge
    }
  };

  return (
    <img
      src={image}
      alt={altText}
      className="fullscreen-image-thumbnail"
      onClick={handleFullscreen}
    />
  );
};

export default Lightbox;
